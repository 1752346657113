const network_plus = {
  id: "network-plus",
  name: "CompTIA Network+",
  description: "Comptia Network+",
  icon: require("../../../../assets/compita-networkplus.png"),
  questions: 90,
  time: 5400,
  exam_id: "N10-009",
  topics: [
    "Networking-Fundamentals",
    "Network-Implementations",
    "Network-Operations",
    "Network-Security",
    "Network-Troubleshooting",
  ],
  info: {
    exam_details: [
      { name: "Exam ID", value: "N10-008" },
      { name: "Number of Questions", value: "Maximum of 90" },
      {
        name: "Question Types",
        value: "Multiple Choice / Performance Based",
      },
      { name: "Length of Test", value: "90 Minutes" },
      {
        name: "Recommended Experience",
        value:
          "Minimum of 9–12 months of hands-on experience working in a junior network administrator/ network support technician job role",
      },
      { name: "Passing Score", value: "720 (on a scale of 100-900)" },
      { name: "Cost of Exam", value: "$369.00" },
      { name: "Validity", value: "3 years" },
    ],
    domains: [
      {
        number: "1.0",
        name: "Networking Fundamentals",
        percentage: "24%",
      },
      {
        number: "2.0",
        name: "Network Implementations",
        percentage: "19%",
      },
      {
        number: "3.0",
        name: "Network Operations",
        percentage: "16%",
      },
      {
        number: "4.0",
        name: "Network Security",
        percentage: "19%",
      },
      {
        number: "5.0",
        name: "Network Troubleshooting",
        percentage: "22%",
      },
    ],
    more_domain_info_available: true,
    more_domain_info_message:
      "For more information, please find the official CompTIA Network+ N10-008 exam objectives:",
    more_domain_info_link:
      "https://partners.comptia.org/docs/default-source/resources/comptia-network-n10-008-exam-objectives-(2-0)",
    study_resources: [
      {
        provider: "Mike Myers",
        provider_link_available: true,
        provider_link: "https://www.totalsem.com/network-plus-certification/",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Professor Messer",
        provider_link_available: true,
        provider_link:
          "https://www.professormesser.com/network-plus/n10-008/n10-008-video/n10-008-training-course/",
        free: true,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Jason Dion",
        provider_link_available: true,
        provider_link: "https://www.diontraining.com/courses/comptia-network",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "PluralSight",
        provider_link_available: true,
        provider_link:
          "https://www.pluralsight.com/paths/comptia-network-n10-008",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "PowerCert Animated Videos",
        provider_link_available: true,
        provider_link: "https://www.youtube.com/watch?v=vrh0epPAC5w",
        free: true,
        video_content: true,
        practice_exams: false,
      },
    ],
  },
};

export default network_plus;
