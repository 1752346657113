import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";

export const Header = () => {
  const [open, setOpen] = useState(false);
  return (
    <Box
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      <AppBar position="static">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "space-between" },
            position: "relative",
            backgroundColor: "#22272b",
          }}
        >
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            <img src={Logo} alt="Logo" style={{ height: 45 }} />
          </Link>
          <MenuIcon
            sx={{ position: "absolute", right: 15, display: { md: "none" } }}
            onClick={() => setOpen(true)}
          />
          <SwipeableDrawer
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
          >
            <Box
              sx={{ width: 250, backgroundColor: "#22272b", height: "100%" }}
              role="presentation"
              onClick={() => setOpen(false)}
              onKeyDown={() => setOpen(false)}
            >
              <List>
                <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                  <ListItem>
                    <ListItemText primary="Home" />
                  </ListItem>
                </Link>
                <Link
                  to="/about"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItem>
                    <ListItemText primary="About Us" />
                  </ListItem>
                </Link>
                {/* <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="#"
                    class="termly-display-preferences"
                  >
                    <ListItem>
                      <ListItemText primary="Consent Preferences" />
                    </ListItem>
                  </a> */}
                <Link
                  to="https://www.certnova.com/privacy-policy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItem>
                    <ListItemText primary="Privacy Policy" />
                  </ListItem>
                </Link>
                <Link
                  to="https://www.certnova.com/cookie-policy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItem>
                    <ListItemText primary="Cookie Policy" />
                  </ListItem>
                </Link>
              </List>
              <Divider />
            </Box>
          </SwipeableDrawer>
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: "20px" }}>
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              <Typography variant="body1">Home</Typography>
            </Link>
            <Link
              to="/about"
              style={{ textDecoration: "none", color: "white" }}
            >
              <Typography variant="body1">About Us</Typography>
            </Link>
            {/* <a
                style={{ textDecoration: "none", color: "white" }}
                href="#"
                
                className="termly-display-preferences"
              >
                <Typography variant="body1">Consent Preferences</Typography>
              </a> */}

            <Link
              to="https://www.certnova.com/privacy-policy.html"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "white" }}
            >
              <Typography variant="body1">Privacy Policy</Typography>
            </Link>

            <Link
              to="https://www.certnova.com/cookie-policy.html"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "white" }}
            >
              <Typography variant="body1">Cookie Policy</Typography>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
