import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";

import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { setCert } from "../../../store/reducers/appSlice";

const CertTile = ({ cert }) => {
  console.log(cert);
  const dispatch = useDispatch();

  const dispatchCert = () => {
    dispatch(setCert(cert));
  };
  return (
    <Grid
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      item
      xs={12}
      sm={6}
      md={4}
    >
      <Link
        onClick={dispatchCert}
        style={{ textDecoration: "none" }}
        to={`/exam/${cert.id}`}
        state={{ cert: cert }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            width: 225,
            height: 225,
            backgroundColor: "#22272b",
            color: "white",
            borderRadius: 3,
            justifyContent: "center",
            alignItems: "center",
            margin: 1,
            ":hover": {
              cursor: "pointer",
              boxShadow: 20,
            },
          }}
        >
          <CardMedia
            sx={{ height: 75, width: 75, margin: "auto", paddingTop: 2 }}
            component="img"
            image={cert.icon}
            alt={cert.name}
          />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="body1">
              {cert.name}
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};

export default CertTile;
