const a_plus_core_2 = {
  id: "a-plus",
  name: "CompTIA A+ (220-1102)",
  description: "Comptia A+",
  icon: require("../../../../assets/comptia-aplus.png"),
  questions: 65,
  time: 5400,
  exam_id: "220-1102",
  topics: [
    "Operating-Systems",
    "Security",
    "Software-Troubleshooting",
    "Operational-Procedures",
  ],
  info: {
    exam_details: [
      { name: "Exam ID", value: "220-1102" },
      { name: "Number of Questions", value: "Maximum of 90" },
      {
        name: "Question Types",
        value: "Multiple Choice / Performance Based",
      },
      { name: "Length of Test", value: "90 Minutes" },
      {
        name: "Recommended Experience",
        value:
          "12 months of hands-on experience in a help desk support technician, desktop support technician, or field service technician job role",
      },
      { name: "Passing Score", value: "700 (on a scale of 100-900)" },
      { name: "Cost of Exam", value: "$253.00" },
      { name: "Validity", value: "3 years" },
    ],
    domains: [
      { number: "1.0", name: "Operating Systems", percentage: "31%" },
      { number: "2.0", name: "Security", percentage: "25%" },
      {
        number: "3.0",
        name: "Software Troubleshooting",
        percentage: "22%",
      },
      {
        number: "4.0",
        name: "Operational Procedures",
        percentage: "22%",
      },
    ],
    more_domain_info_available: true,
    more_domain_info_message:
      "For more information, please find the official CompTIA A+ 220-1102 exam objectives:",
    more_domain_info_link:
      "https://partners.comptia.org/docs/default-source/resources/comptia-a-220-1102-exam-objectives-(3-0)",
    study_resources: [
      {
        provider: "Mike Myers",
        provider_link_available: true,
        provider_link: "https://www.totalsem.com/a-certification/",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Professor Messer",
        provider_link_available: true,
        provider_link:
          "https://www.professormesser.com/free-a-plus-training/220-1102/220-1102-video/220-1102-training-course/",
        free: true,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Jason Dion",
        provider_link_available: true,
        provider_link: "https://www.diontraining.com/courses/comptia-a-core-2",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "PluralSight",
        provider_link_available: true,
        provider_link:
          "https://www.pluralsight.com/paths/comptia-a-core-2-220-1102",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Udemy",
        provider_link_available: true,
        provider_link: "https://www.udemy.com/comptia-a-core-2-220-1102/",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "PowerCert Animated Videos",
        provider_link_available: true,
        provider_link: "https://www.youtube.com/watch?v=2eLe7uz-7CM",
        free: true,
        video_content: true,
        practice_exams: false,
      },
    ],
  },
};

export default a_plus_core_2;
