import { Box, Typography } from "@mui/material";
import colors from "../../../../utils/colors";

const InstantFeedbackComponent = ({ question }) => {
  return (
    <Box sx={{ padding: 1 }}>
      <Typography
        variant="body2"
        component="div"
        sx={{ marginLeft: 2, marginBottom: 1 }}
      >
        Correct Answers:
      </Typography>
      {question.ANSWERS.filter((answer) => answer.CORRECT).map((answer) => (
        <Box
          key={answer.ANSWER_TEXT}
          sx={{ borderLeft: "3px solid lightblue", borderRadius: 5 }}
        >
          <Box
            sx={{
              borderBottom: "2px solid lightblue",
              padding: 1,
              borderRadius: 5,
              backgroundColor: colors.primary,
            }}
          >
            <Typography
              variant="body2"
              component="div"
              sx={{ marginLeft: 2, marginBottom: 1 }}
            >
              ANSWER: {answer.ANSWER_TEXT}
            </Typography>
            <Typography variant="body2" component="div" sx={{ marginLeft: 2 }}>
              EXPLANATION: {answer.EXPLANATION}
            </Typography>
          </Box>
        </Box>
      ))}

      <br />
      <Typography
        variant="body2"
        component="div"
        sx={{ marginLeft: 2, marginBottom: 1 }}
      >
        Other Answers:
      </Typography>
      {question.ANSWERS.filter((answer) => !answer.CORRECT).map((answer) => (
        <Box
          key={answer.ANSWER_TEXT}
          sx={{ borderLeft: "3px solid red", borderRadius: 5 }}
        >
          <Box
            sx={{
              borderBottom: "2px solid red",
              padding: 1,
              borderRadius: 5,
              backgroundColor: colors.primary,
            }}
          >
            <Typography
              variant="body2"
              component="div"
              sx={{ marginLeft: 2, marginBottom: 1 }}
            >
              ANSWER: {answer.ANSWER_TEXT}
            </Typography>
            <Typography variant="body2" component="div" sx={{ marginLeft: 2 }}>
              EXPLANATION: {answer.EXPLANATION}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default InstantFeedbackComponent;
