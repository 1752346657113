import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Header } from "./components/header/Header";
import Main from "./pages/main/Main";
import Exam from "./pages/exam/Exam";
import About from "./pages/about/About";
import Footer from "./components/footer/Footer";
import TestComponent from "./pages/exam/components/TestComponent";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "60px",
            marginBottom: "50px",
          }}
        >
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/exam/:id" element={<Exam />} />
            <Route path="/about" element={<About />} />
            <Route path="/test" element={<TestComponent />} />
            <Route path="*" element={<Main />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
