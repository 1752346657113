import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import QuestionSummaryItem from "./questions/QuestionSummaryItem";
import { useSelector } from "react-redux";
import { useState } from "react";

const ExamSummary = ({
  computeColor,
  handleClickOpenReportDialog,
  pastExamIndex,
}) => {
  const exam = useSelector((store) => store.app.pastExams[pastExamIndex]);
  const examFilters = ["All", "Correct", "Incorrect", "Skipped"];
  const numCorrect = exam.settings.numCorrect;
  const [filter, setFilter] = useState("All");
  const [questions, setQuestions] = useState(exam.questions);

  const updateFilter = (event) => {
    switch (event.target.value) {
      case "Correct":
        setQuestions(exam.questions.filter((question) => question.CORRECT));
        setFilter("Correct");
        break;
      case "Incorrect":
        setQuestions(exam.questions.filter((question) => !question.CORRECT));
        setFilter("Incorrect");
        break;
      case "Skipped":
        setQuestions(exam.questions.filter((question) => question.SKIPPED));
        setFilter("Skipped");
        break;
      default:
        setQuestions(exam.questions);
        setFilter("All");
        break;
    }
  };
  return (
    <Box sx={{ margin: 1 }}>
      <Typography
        sx={{ fontSize: 20, textAlign: "center" }}
        variant="body2"
        component="div"
      >
        Thank you for taking an exam!
      </Typography>
      <br />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "50%" }}>
          <Typography variant="body1" component="div">
            Total Correct: {numCorrect} / {exam.questions.length}
          </Typography>
          <Typography variant="body1" component="div">
            Your Score:{" "}
            {((numCorrect / exam.questions.length) * 100).toFixed(2)}%
          </Typography>
        </Box>

        <FormControl sx={{ width: "50%" }}>
          <InputLabel sx={{ color: "white" }} id="filter">
            Filter
          </InputLabel>
          <Select
            labelId="filter"
            id="filter-select"
            value={filter}
            label="Topic"
            onChange={updateFilter}
            sx={{ color: "white" }}
          >
            {examFilters.map((examFilter) => (
              <MenuItem key={examFilter} value={examFilter}>
                {examFilter}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <br />
      {questions.map((question) => (
        <QuestionSummaryItem
          key={question.ID}
          question={question}
          computeColor={computeColor}
          handleClickOpenReportDialog={handleClickOpenReportDialog}
          index={exam.questions.indexOf(question)}
        />
      ))}
    </Box>
  );
};
export default ExamSummary;
