import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import colors from "../../../../utils/colors";

const QuestionSummaryItem = ({
  question,
  handleClickOpenReportDialog,
  index,
}) => {
  const userAnswerTexts = question.USER_ANSWERS?.map(
    (answer) => answer.ANSWER_TEXT
  );

  return (
    <Box key={question.ID}>
      <Box
        sx={{
          backgroundColor: colors.gray,
          borderRadius: 2,
          padding: 1,
        }}
      >
        <span style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontWeight: "bold",
              backgroundColor: question.SKIPPED
                ? "black"
                : question.CORRECT
                ? colors.green
                : colors.red,
              padding: 1,
              borderRadius: 2,
            }}
            variant="body1"
            component="div"
          >
            Question {index + 1} -{" "}
            {question.SKIPPED
              ? "SKIPPED"
              : question.CORRECT
              ? "CORRECT"
              : "INCORRECT"}
          </Typography>
          <Button
            variant="contained"
            size="small"
            onClick={handleClickOpenReportDialog.bind(this, question)}
            sx={{
              color: "white",
            }}
          >
            Report Question
          </Button>
        </span>
        <br />
        <Typography sx={{ fontWeight: "bold" }} variant="body1" component="div">
          {question.PROMPT}
        </Typography>

        <br />
        {question.USER_ANSWERS?.length > 0 && (
          <Box>
            <Typography variant="body2" component="div">
              Your answers:{" "}
            </Typography>
            {question.USER_ANSWERS?.map((answer) => (
              <Box
                key={answer.ANSWER_TEXT}
                sx={{
                  borderLeft: answer.CORRECT
                    ? "3px solid green"
                    : "3px solid red",
                  borderRadius: 5,
                }}
              >
                <Box
                  sx={{
                    padding: 1,
                    marginBottom: 1,
                    borderRadius: 5,
                    backgroundColor: colors.primary,
                    borderBottom: answer.CORRECT
                      ? "2px solid green"
                      : "2px solid red",
                  }}
                >
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{
                      marginLeft: 2,
                      marginBottom: 1,
                    }}
                  >
                    ANSWER: {answer.ANSWER_TEXT}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{ marginLeft: 2 }}
                  >
                    EXPLANATION: {answer.EXPLANATION}
                  </Typography>
                </Box>
              </Box>
            ))}
            <br />
          </Box>
        )}

        <Typography variant="body2" component="div">
          Correct answer(s):{" "}
        </Typography>
        {question.ANSWERS.filter((answer) => answer.CORRECT).map((answer) => (
          <Box
            key={answer.ANSWER_TEXT}
            sx={{ borderLeft: "3px solid lightblue", borderRadius: 5 }}
          >
            <Box
              sx={{
                borderBottom: "2px solid lightblue",
                padding: 1,
                borderRadius: 5,
                backgroundColor: colors.primary,
              }}
            >
              <Typography
                variant="body2"
                component="div"
                sx={{ marginLeft: 2, marginBottom: 1 }}
              >
                ANSWER: {answer.ANSWER_TEXT}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={{ marginLeft: 2 }}
              >
                EXPLANATION: {answer.EXPLANATION}
              </Typography>
            </Box>
          </Box>
        ))}

        {question.ANSWERS.filter(
          (answer) =>
            !userAnswerTexts?.includes(answer.ANSWER_TEXT) && !answer.CORRECT
        ).length > 0 ? (
          <Box>
            <br />
            <Typography variant="body2" component="div">
              Other answers:{" "}
            </Typography>
            {question.ANSWERS.filter(
              (answer) =>
                !userAnswerTexts?.includes(answer.ANSWER_TEXT) &&
                !answer.CORRECT
            ).map((answer) => (
              <Box
                key={answer.ANSWER_TEXT}
                sx={{ borderLeft: "3px solid red", borderRadius: 5 }}
              >
                <Box
                  sx={{
                    borderBottom: "2px solid red",
                    padding: 1,
                    borderRadius: 5,
                    backgroundColor: colors.primary,
                  }}
                >
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{ marginLeft: 2, marginBottom: 1 }}
                  >
                    ANSWER: {answer.ANSWER_TEXT}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{ marginLeft: 2 }}
                  >
                    EXPLANATION: {answer.EXPLANATION}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        ) : null}
      </Box>
      <br />
    </Box>
  );
};

export default QuestionSummaryItem;
