export default {
  primary: "#1d2025",
  primaryLight: "#1d2029",
  primaryDark: "#002980",
  secondary: "##22272b",
  secondaryLight: "#ff4081",
  secondaryDark: "#c51162",
  grayLight: "#4a4d51",
  gray: "#3c3f43",
  green: "#1b5e10",
  red: "#c0392b",
};
